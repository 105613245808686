import React, { Component } from "react";

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBInput,
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBCol,
} from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faFileUpload,
  faPlusCircle,
  faPowerOff,
  faSatelliteDish,
  faUpload,
  faTrash,
  faUsers,
  faDesktop,
  faEnvelope,
  faCogs,
  faVoteYea,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import { Button, Col, Form, Modal } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import Usuarios from "../pages/Usuarios";
class Navegacion extends Component {
  state = {
    collapseID: "",
    redirect: false,
    recarga: false,
    usuario_id: "",
    id: "",
    titulo: "",
    descripcion: "",
    importancia: "3",
    fechahora: "",
    visto: "",
    adjuntosList: [],
  };

  showModal = () => {
    this.setState({ modal: true });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  ModalNuevo = () => {
    this.showModal();
    this.setState({
      modalLabel: "Guardar",
      variante: "primary",
      modalAccion: "insert",
      modalTitle: "Registro nuevo",
      id: "",
      titulo: "",
      descripcion: "",
      importancia: "3",
      fechahora: "",
      visto: "",
      adjuntosList: [],
    });
  };

  validaFormulario = (e) => {
    e.preventDefault();
    if (this.state.titulo === "")
      toast.error("El título es requerido. ", { autoClose: 2000 });
    else if (this.state.descripcion === "")
      toast.error("La información a detalle es requerida. ", {
        autoClose: 2000,
      });
    else this.ejecutar();
  };

  ejecutar = (e) => {
    this.setState({ loading: true });
    switch (this.state.modalAccion) {
      case "update":
        this.actualiza();
        break;
      case "insert":
        this.registra();
        break;
      case "delete":
        this.borra();
        break;
      default:
    }
  };

  onChangeTitulo = (e) => {
    this.setState({ titulo: e.target.value });
  };

  onChangeDescripcion = (e) => {
    this.setState({ descripcion: e.target.value });
  };

  onChangeImportancia = (e) => {
    this.setState({ importancia: e.target.value });
  };

  onChangeFechahora = (e) => {
    this.setState({ fechahora: e.target.value });
  };

  onChangeVisto = (e) => {
    this.setState({ visto: e.target.value });
  };

  registra = () => {
    let data = {
      //Recoger la información para el registro
      titulo: this.state.titulo,
      descripcion: this.state.descripcion,
      importancia: this.state.importancia,
      fechahora: this.state.fechahora,
      usuario_id: sessionStorage.id,
      visto: this.state.visto,
      adjuntos: this.state.adjuntosList,
      api_token: sessionStorage.api_token,
    };
    let config = {};

    Axios.post("https://" + process.env.REACT_APP_URL + "/api/Reporte", data, config).then(
      (res) => {
        this.closeModal();
        toast.success(res.data.text, { autoClose: 1000 });
        this.setState({ loading: false });
        this.setRecarga();
      }
    );
  };

  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/logout" />;
    }
  };
  setRecarga = () => {
    this.setState({
      recarga: true,
    });
  };

  adjuntoChangeDescription = (adjunto, indx, e) => {
    const adjuntosList = this.state.adjuntosList;
    const data = { file: adjunto.file, descripcion: e.target.value };
    adjuntosList[indx] = data;
    this.setState({ adjuntosList: adjuntosList });
  };

  adjuntoRemove = (indx) => {
    var adjuntosList = this.state.adjuntosList;
    adjuntosList.splice(indx, 1);
    this.setState({ adjuntosList: adjuntosList });
  };

  renderRecarga = () => {
    if (this.state.recarga) {
      return <Redirect to="/" />;
    }
  };

  onFileChange = ({ target }) => {
    const adjuntosList = this.state.adjuntosList;
    for (let i = 0; i < target.files.length; i++) {
      const data = { file: target.files[i], descripcion: "" };
      adjuntosList.push(data);
    }
    this.setState({ adjuntosList: adjuntosList });
  };

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  render() {
    return (
      <div>
        {this.renderRedirect()}
        {this.renderRecarga()}

        <MDBNavbar color="unique-color" className="cabecera" dark expand="md">
          <MDBNavbarBrand href="#">
            <img src={process.env.REACT_APP_URL + "/img/logo_nav.png"} height="30" alt="" />
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={this.toggleCollapse("navbarCollapse")} />
          <MDBCollapse
            id="navbarCollapse"
            isOpen={this.state.collapseID}
            navbar
          >
            <MDBNavbarNav left>
              <MDBNavLink to="/escritorio">
                <FontAwesomeIcon icon={faDesktop} size="lg" /> Escritorio
              </MDBNavLink>
              {sessionStorage.nivel == "5" && (
                <MDBNavLink to="/sistema">
                  <FontAwesomeIcon icon={faCogs} size="lg" /> Sistema
                </MDBNavLink>
              )}
              <MDBNavLink to="/grupos">
                <FontAwesomeIcon icon={faUsers} size="lg" /> Grupos
              </MDBNavLink>

              <MDBNavLink to="/personas">
                <FontAwesomeIcon icon={faUsers} size="lg" /> Personas
              </MDBNavLink>

              {sessionStorage.nivel == "5" && (
                <MDBNavLink to="/mensajes">
                  <FontAwesomeIcon icon={faEnvelope} size="lg" /> Mensajes
                </MDBNavLink>
              )}

              {/* <a className="nav-link Ripple-parent" onClick={this.ModalNuevo}>
                <FontAwesomeIcon icon={faSatelliteDish} size="lg" /> Informar
              </a> */}

              {/* <a className="nav-link Ripple-parent">
                <FontAwesomeIcon icon={faVoteYea} size="lg" /> Dia D
              </a> */}

              {/* <a className="nav-link Ripple-parent">
                <FontAwesomeIcon icon={faMapMarkedAlt} size="lg" /> Mapeo
              </a> */}
            </MDBNavbarNav>
            <MDBNavbarNav right>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <MDBIcon icon="user" className="mr-1" />
                    {sessionStorage.nombre}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className="dropdown-default" right>
                    <MDBDropdownItem
                      className="dropdown-item"
                      onClick={this.setRedirect}
                    >
                      <FontAwesomeIcon icon={faPowerOff} size="lg" /> Cerrar
                      Sesion
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
        <Modal //***************************Modal para nuevo y modificar
          show={this.state.modal}
          onHide={this.closeModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Informe a la base </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Informe al candidato y a la estructura de sus actividades en
            beneficio de la campaña.
            <MDBInput
              label="Escriba un titulo breve"
              group
              type="text"
              validate
              error="wrong"
              success="right"
              name="titulo"
              value={this.state.titulo}
              onChange={this.onChangeTitulo}
              outline
            />
            <MDBInput
              label="Información Detallada"
              group
              type="textarea"
              validate
              error="wrong"
              success="right"
              name="descripcion"
              value={this.state.descripcion}
              onChange={this.onChangeDescripcion}
              outline
            />
            Importancia
            <MDBInput
              type="range"
              min="1"
              max="5"
              step="1"
              label=""
              group
              validate
              error="wrong"
              success="right"
              name="importancia"
              value={this.state.importancia}
              onChange={this.onChangeImportancia}
              outline
            />
            <Form.Row>
              <Col>
                <MDBContainer style={{ maxHeight: "150px", overflow: "auto" }}>
                  {this.state.adjuntosList.map((adjunto, indx) => {
                    var imgShow = URL.createObjectURL(adjunto.file);
                    return (
                      <MDBRow key={indx}>
                        <MDBCol size="1">
                          <img
                            src={imgShow}
                            alt="thumbnail"
                            className="img-thumbnail"
                            style={{ maxWidth: "55px" }}
                          />
                        </MDBCol>
                        <MDBCol size="5">{adjunto.file.name}</MDBCol>
                        <MDBCol size="5">
                          <MDBInput
                            label="Descripción de la Imagen"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            name="descripcion"
                            onChange={(e) =>
                              this.adjuntoChangeDescription(adjunto, indx, e)
                            }
                            outline
                            value={adjunto.descripcion}
                          />
                        </MDBCol>
                        <MDBCol size="1">
                          <Button
                            variant="danger"
                            className="btn-rounded"
                            size="sm"
                            onClick={(eZ) => this.adjuntoRemove(indx)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </MDBCol>
                      </MDBRow>
                    );
                  })}
                </MDBContainer>
              </Col>

              <Col xs="12" className="text-center">
                <label
                  className="btn btn-primary"
                  color="primary"
                  htmlFor="agregaAdjunto"
                >
                  <input
                    style={{ display: "none" }}
                    id="agregaAdjunto"
                    name="agregaAdjunto"
                    type="file"
                    accept="image/*"
                    onChange={this.onFileChange}
                    multiple={true}
                  />
                  <FontAwesomeIcon icon={faPlusCircle} /> Agrega Imagen
                </label>
              </Col>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Cancelar
            </Button>
            <Button variant={this.state.variante} onClick={this.validaFormulario} disabled={this.state.loading}>
              {this.state.loading ? "Guardando..." : this.state.modalLabel}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Navegacion;
