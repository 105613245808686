import React, { Component } from "react";
import Layout from "../layout/Layout";
import PropagateLoader from "react-spinners/PropagateLoader";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

class Usuarios extends Component {
  constructor(props) {
    super(props);
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.state = {
      // Estado relacionado con el modelo
      id: "",
      usuario: "",
      password: "",
      nombre: "",
      ap_paterno: "",
      ap_materno: "",
      rol: "",
      rol_texto: "",
      rolOptions: "",
      id_ambito: "",
      id_padre: "",
      nivel: 0,
      api_token: "",
      endpoint: "",

      // Estados para la ventana modal
      modal: false, // ventana modal generica para modificar y Nuevo cerrada
      modalEliminar: false, //Modal Eliminar
      modalAccion: "", // Acción del boton principal de la ventana modal para el switch
      modalTitle: "", // Titulo para la ventana modal generica
      usuarios: [], // Listado de elementos para la tabla
      loading: false,
    };
  }

  componentDidMount = () => {
    this.cargaTabla();
  };
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  cargaTabla = () => {
    this.setState({ loading: true });
    axios({
      method: "get",
      url:
        "https://" + process.env.REACT_APP_URL + "/api/Usuario?api_token=" +
        sessionStorage.api_token,
      data: {},
    }).then((res) => {
      this.setState({ usuarios: res.data, loading: false });
    });

    /* Alimenta el select de promotores*/
    axios({
      method: "get",
      url:
        "https://" + process.env.REACT_APP_URL + "/api/Rol?api_token=" +
        sessionStorage.api_token,
      data: {},
    }).then((res) => {
      let opciones = [];
      res.data.map((opc) => {
        opciones.push({ value: opc.id, label: opc.nombre });
      });
      this.setState({ rolOptions: opciones, loading: false });
    });
  };

  showModalEliminar = () => {
    this.setState({ modalEliminar: true });
  };

  closeModalEliminar = () => {
    this.setState({ modalEliminar: false });
  };

  showModal = () => {
    this.setState({ modal: true });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  ModalNuevo = () => {
    this.showModal();
    this.setState({
      modalLabel: "Guardar",
      variante: "primary",
      modalAccion: "insert",
      modalTitle: "Registro nuevo",
      id: "",
      usuario: "",
      password: "",
      nombre: "",
      ap_paterno: "",
      ap_materno: "",
      rol: "",
      rol_texto: "",
      id_ambito: "",
      id_padre: "",
      nivel: 0,
      api_token: "",
      endpoint: "",
    });
  };

  ModalModificar = (row) => {
    this.showModal();
    this.setState({
      modalLabel: "Modificar",
      variante: "warning",
      modalAccion: "update",
      modalTitle: "Modificación",
      id: row.id,
      usuario: row.usuario,
      password: row.password,
      nombre: row.nombre,
      ap_paterno: row.ap_paterno,
      ap_materno: row.ap_materno,
      rol: row.rol,
      id_ambito: row.id_ambito,
      id_padre: row.id_padre,
      nivel: row.nivel,
      api_token: row.api_token,
      endpoint: row.endpoint,
    });
  };

  ModalEliminar = (row) => {
    this.showModalEliminar();
    this.setState({
      modalAccion: "delete",
      id: row.id,
      usuario: row.usuario,
      password: row.password,
      nombre: row.nombre,
      ap_paterno: row.ap_paterno,
      ap_materno: row.ap_materno,
      rol: row.rol,
      id_ambito: row.id_ambito,
      id_padre: row.id_padre,
      nivel: row.nivel,
      api_token: row.api_token,
      endpoint: row.endpoint,
    });
  };

  ejecutar = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    switch (this.state.modalAccion) {
      case "update":
        this.actualiza();
        break;
      case "insert":
        this.registra();
        break;
      case "delete":
        this.borra();
        break;
      default:
    }
  };

  registra = () => {
    let data = {
      //Recoger la información para el registro
      usuario: this.state.usuario,
      password: this.state.password,
      nombre: this.state.nombre,
      ap_paterno: this.state.ap_paterno,
      ap_materno: this.state.ap_materno,
      rol: this.state.rol,
      id_ambito: this.state.id_ambito,
      id_padre: this.state.id_padre,
      nivel: this.state.nivel,
      api_token: this.state.api_token,
      endpoint: this.state.endpoint,
      api_token: sessionStorage.api_token,
    };
    let config = {};

    axios
      .post("https://" + process.env.REACT_APP_URL + "/api/Usuario", data, config)
      .then((res) => {
        this.closeModal();
        toast.success(res.data.text, { autoClose: 1000 });
        this.cargaTabla();
        this.setState({ loading: false });
      });
  };

  actualiza = () => {
    let data = {
      //Recoger la información para el registro
      id: this.state.id,
      usuario: this.state.usuario,
      password: this.state.password,
      nombre: this.state.nombre,
      ap_paterno: this.state.ap_paterno,
      ap_materno: this.state.ap_materno,
      rol: this.state.rol,
      id_ambito: this.state.id_ambito,
      id_padre: this.state.id_padre,
      nivel: this.state.nivel,
      api_token: this.state.api_token,
      endpoint: this.state.endpoint,
      api_token: sessionStorage.api_token,
    };
    let config = {};

    axios
      .put(
        "https://" + process.env.REACT_APP_URL + "/api/Usuario/" + this.state.id,
        data,
        config
      )
      .then((res) => {
        this.closeModal();
        toast.success(res.data.text, { autoClose: 1000 });
        this.cargaTabla();
        this.setState({ loading: false });
      });
  };

  borra = () => {
    let data = {
      api_token: sessionStorage.api_token,
    };
    let config = {};
    axios
      .delete(
        "https://" + process.env.REACT_APP_URL + "/api/Usuario/" +
        this.state.id +
        "?api_token=" +
        sessionStorage.api_token,
        data,
        config
      )
      .then((res) => {
        this.closeModalEliminar();
        toast.success(res.data.text, { autoClose: 1000 });
        this.cargaTabla();
        this.setState({ loading: false });
      });
  };

  onChangeUsuario = (e) => {
    this.setState({ usuario: e.target.value });
  };

  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  onChangeNombre = (e) => {
    this.setState({ nombre: e.target.value });
  };

  onChangeApPaterno = (e) => {
    this.setState({ ap_paterno: e.target.value });
  };

  onChangeApMaterno = (e) => {
    this.setState({ ap_materno: e.target.value });
  };

  onChangeRol = (selectedOption) => {
    // this.setState({ rol: e.target.value });
    this.setState({ rol: selectedOption });
    this.setState({ rol_id: selectedOption.value });
  };

  onChangeIdAmbito = (e) => {
    this.setState({ id_ambito: e.target.value });
  };

  onChangeIdPadre = (e) => {
    this.setState({ id_padre: e.target.value });
  };

  onChangeNivel = (e) => {
    this.setState({ nivel: e.target.value });
  };

  onChangeApiToken = (e) => {
    this.setState({ api_token: e.target.value });
  };

  onChangeEndpoint = (e) => {
    this.setState({ endpoint: e.target.value });
  };

  GetActionFormat = (cell, row) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-outline-primary btn-sm ts-buttom"
          size="sm"
          onClick={() => this.ModalModificar(row)}
          data-tip="Modificar"
        >
          <FontAwesomeIcon icon={faEdit} size="lg" />
        </button>
        <button
          type="button"
          className="btn btn-outline-danger btn-sm ml-2 ts-buttom"
          size="sm"
          onClick={() => this.ModalEliminar(row)}
          data-tip="Eliminar"
        >
          <FontAwesomeIcon icon={faTrash} size="lg" />
        </button>
      </div>
    );
  };

  render() {
    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
        headerStyle: () => {
          return { width: "10%" };
        },
      },
      {
        dataField: "usuario",
        text: "Usuario",
        sort: true,
      },
      {
        dataField: "nombre",
        text: "Nombre",
        sort: true,
      },
      {
        dataField: "ap_paterno",
        text: "Ap Paterno",
        sort: true,
      },
      {
        dataField: "ap_materno",
        text: "Ap Materno",
        sort: true,
      },
      {
        dataField: "rol_texto",
        text: "Rol",
        sort: true,
      },
      {
        dataField: "nivel",
        text: "Nivel",
        sort: true,
      },

      {
        dataField: "",
        text: "Acción",
        formatter: this.GetActionFormat,
        classes: "p-1",
        headerStyle: () => {
          return { width: "25%" };
        },
      },
    ];

    const { SearchBar } = Search;

    return (
      <Layout>
        <ToastContainer />
        <div
          id="cargando"
          className={this.state.loading ? "parentDisable" : ""}
          width="100%"
        >
          <div className="overlay-box">
            <PropagateLoader
              // css={}
              sizeUnit={"px"}
              size={25}
              color={"#000000"}
              loading={this.state.loading}
            />
          </div>
        </div>

        <div className="container mt-3">
          <div className="row">
            <div className="col">
              <h3 className="d-inline">Catálogo de Usuarios</h3>{" "}
              <small className="text-muted">
                Registre Elimine o Modifique Usuarios, estos elementos estan
                ligados a los usuarios de el sistema.
              </small>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 ">
              <ToolkitProvider
                keyField="id"
                data={this.state.usuarios}
                columns={columns}
                bootstrap4
                search
              >
                {(props) => (
                  <div>
                    <div className="row">
                      <div className="col-6">
                        <button
                          className="btn btn-primary"
                          onClick={this.ModalNuevo}
                          data-tip="Nuevo"
                        >
                          <FontAwesomeIcon icon={faPlus} /> Nuevo
                        </button>
                      </div>
                      <div className="col-6 text-right">
                        <SearchBar
                          placeholder="Buscar..."
                          {...props.searchProps}
                          className="self-align-end"
                        />
                      </div>
                    </div>

                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory()}
                      hover
                    />
                  </div>
                )}
              </ToolkitProvider>{" "}
            </div>
          </div>
        </div>

        <Modal //***************************Modal para nuevo y modificar
          show={this.state.modal}
          onHide={this.closeModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                Usuario:
              <input
                  className="form-control"
                  type="text"
                  name="usuario"
                  value={this.state.usuario}
                  onChange={this.onChangeUsuario}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                Password:
            <input
                  className="form-control"
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChangePassword}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                Nombre:
            <input
                  className="form-control"
                  type="text"
                  name="nombre"
                  value={this.state.nombre}
                  onChange={this.onChangeNombre}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                Ap Paterno:
            <input
                  className="form-control"
                  type="text"
                  name="ap_paterno"
                  value={this.state.ap_paterno}
                  onChange={this.onChangeApPaterno}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                Ap Materno:
            <input
                  className="form-control"
                  type="text"
                  name="ap_materno"
                  value={this.state.ap_materno}
                  onChange={this.onChangeApMaterno}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                Rol:
            <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="rol"
                  options={this.state.rolOptions}
                  value={this.state.rol}
                  onChange={this.onChangeRol}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-11">
                <label htmlFor="nivel">Nivel:</label>
                <input
                  type="range"
                  className="custom-range"
                  id="nivel"
                  name="nivel"
                  min={0} max={5}
                  value={this.state.nivel}
                  onChange={this.onChangeNivel} />
              </div>
              <div className="col-1">
                <br></br>
                <h5><b>{this.state.nivel}</b></h5>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Cancelar
            </Button>
            <Button variant={this.state.variante} onClick={this.ejecutar} disabled={this.state.loading}>
              {this.state.loading ? "Guardando..." : this.state.modalLabel}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal //*******************************Modal para eliminar
          show={this.state.modalEliminar}
          onHide={this.closeModalEliminar}
          size="sm"
          centered
        >
          <Modal.Header>
            <Modal.Title>Eliminar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Seguro que desea eliminar este registro{" "}
            <strong>{this.state.nom_direccion}</strong> ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModalEliminar}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={this.ejecutar}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
        <ReactTooltip />
      </Layout>
    );
  }
}

export default Usuarios;
