import React, { Component } from "react";
import Layout from "../layout/Layout";
import PropagateLoader from "react-spinners/PropagateLoader";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReactTooltip from "react-tooltip";
import { ButtonGroup } from "react-bootstrap";

class Grupos extends Component {
  constructor(props) {
    super(props);
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.state = {
      // Estado relacionado con el modelo
      id: "",
      nombre: "",
      descripcion: "",

      // Estados para la ventana modal
      modal: false, // ventana modal generica para modificar y Nuevo cerrada
      modalEliminar: false, //Modal Eliminar
      modalAccion: "", // Acción del boton principal de la ventana modal para el switch
      modalTitle: "", // Titulo para la ventana modal generica
      grupos: [], // Listado de elementos para la tabla
      loading: false,
    };
  }

  componentDidMount = () => {
    this.cargaTabla();
  };
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  cargaTabla = () => {
    this.setState({ loading: true });
    axios({
      method: "get",
      url:
        "https://" + process.env.REACT_APP_URL + "/api/Grupo?api_token=" +
        sessionStorage.api_token,
      data: {},
    }).then((res) => {
      this.setState({ grupos: res.data, loading: false });
    });
  };

  showModalEliminar = () => {
    this.setState({ modalEliminar: true });
  };

  closeModalEliminar = () => {
    this.setState({ modalEliminar: false });
  };

  showModal = () => {
    this.setState({ modal: true });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  ModalNuevo = () => {
    this.showModal();
    this.setState({
      modalLabel: "Guardar",
      variante: "primary",
      modalAccion: "insert",
      modalTitle: "Registro nuevo",
      id: "",
      nombre: "",
      descripcion: "",
    });
  };

  ModalModificar = (row) => {
    this.showModal();
    this.setState({
      modalLabel: "Modificar",
      variante: "warning",
      modalAccion: "update",
      modalTitle: "Modificación",
      id: row.id,
      nombre: row.nombre,
      descripcion: row.descripcion,
    });
  };

  ModalEliminar = (row) => {
    this.showModalEliminar();
    this.setState({
      modalAccion: "delete",
      id: row.id,
      nombre: row.nombre,
      descripcion: row.descripcion,
    });
  };

  ejecutar = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    switch (this.state.modalAccion) {
      case "update":
        this.actualiza();
        break;
      case "insert":
        this.registra();
        break;
      case "delete":
        this.borra();
        break;
      default:
    }
  };

  registra = () => {
    let data = {
      //Recoger la información para el registro
      nombre: this.state.nombre,
      descripcion: this.state.descripcion,
      api_token: sessionStorage.api_token,
    };
    let config = {};

    axios
      .post("https://" + process.env.REACT_APP_URL + "/api/Grupo", data, config)
      .then((res) => {
        this.closeModal();
        toast.success(res.data.text, { autoClose: 1000 });
        this.cargaTabla();
        this.setState({ loading: false });
      });
  };

  actualiza = () => {
    let data = {
      //Recoger la información para el registro
      id: this.state.id,
      nombre: this.state.nombre,
      descripcion: this.state.descripcion,
      api_token: sessionStorage.api_token,
    };
    let config = {};

    axios
      .put(
        "https://" + process.env.REACT_APP_URL + "/api/Grupo/" + this.state.id,
        data,
        config
      )
      .then((res) => {
        this.closeModal();
        toast.success(res.data.text, { autoClose: 1000 });
        this.cargaTabla();
        this.setState({ loading: false });
      });
  };

  borra = () => {
    let data = {
      api_token: sessionStorage.api_token,
    };
    let config = {};
    axios
      .delete(
        "https://" + process.env.REACT_APP_URL + "/api/Grupo/" +
        this.state.id +
        "?api_token=" +
        sessionStorage.api_token,
        data,
        config
      )
      .then((res) => {
        this.closeModalEliminar();
        toast.success(res.data.text, { autoClose: 1000 });
        this.cargaTabla();
        this.setState({ loading: false });
      });
  };

  onChangeNombre = (e) => {
    this.setState({ nombre: e.target.value });
  };

  onChangeDescripcion = (e) => {
    this.setState({ descripcion: e.target.value });
  };

  GetActionFormat = (cell, row) => {
    return (
      <div>
        <ButtonGroup size="sm">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            size="sm"
            onClick={() => this.ModalModificar(row)}
            data-tip="Modificar"
            disabled
          >
            <FontAwesomeIcon icon={faEdit} size="lg" />
          </button>

          <button
            type="button"
            className="btn btn-outline-danger btn-sm"
            size="sm"
            onClick={() => this.ModalEliminar(row)}
            data-tip="Eliminar"
            disabled
          >
            <FontAwesomeIcon icon={faTrash} size="lg" />
          </button>
        </ButtonGroup>
      </div>
    );
  };

  render() {
    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
        headerStyle: () => {
          return { maxWidth: "100px" };
        },
      },
      {
        dataField: "nombre",
        text: "Nombre",
        sort: true,
        headerStyle: () => {
          return { minWidth: "500px" };
        },
      },
      {
        dataField: "descripcion",
        text: "Descripcion",
        sort: true,
        headerStyle: () => {
          return { minWidth: "500px" };
        },
      },

      {
        dataField: "",
        text: "Acción",
        formatter: this.GetActionFormat,
        classes: "p-1",
        headerStyle: () => {
          return { width: "140px" };
        },
      },
    ];

    const { SearchBar } = Search;

    return (
      <Layout>
        <ToastContainer />
        <div
          id="cargando"
          className={this.state.loading ? "parentDisable" : ""}
          width="100%"
        >
          <div className="overlay-box">
            <PropagateLoader
              // css={}
              sizeUnit={"px"}
              size={25}
              color={"#000000"}
              loading={this.state.loading}
            />
          </div>
        </div>

        <div className="container mt-3">
          <div className="row">
            <div className="col">
              <h3 className="d-inline">Catálogo de Grupos</h3>{" "}
              <small className="text-muted">
                Gestione grupos de personas utiles para el desarrollo de la
                campaña, (Promovidos, Estructura, Lideres, Equipos de Trabajo
                incluso Adversarios).
              </small>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 ">
              <ToolkitProvider
                keyField="id"
                data={this.state.grupos}
                columns={columns}
                bootstrap4
                search
              >
                {(props) => (
                  <div>
                    <div className="row">
                      <div className="col-6">
                        <button
                          className="btn btn-primary"
                          onClick={this.ModalNuevo}
                          data-tip="Nuevo"
                          disabled={process.env.REACT_APP_URL == 'bunker.sii.mx' ? false : true}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Nuevo
                        </button>
                      </div>
                      <div className="col-6 text-right">
                        <SearchBar
                          placeholder="Buscar..."
                          {...props.searchProps}
                          className="self-align-end"
                        />
                      </div>
                    </div>

                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory()}
                      hover
                    />
                  </div>
                )}
              </ToolkitProvider>{" "}
            </div>
          </div>
        </div>

        <Modal //***************************Modal para nuevo y modificar
          show={this.state.modal}
          onHide={this.closeModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Nombre:
            <input
              className="form-control"
              type="text"
              name="nombre"
              value={this.state.nombre}
              onChange={this.onChangeNombre}
            />
            Descripcion:
            <input
              className="form-control"
              type="text"
              name="descripcion"
              value={this.state.descripcion}
              onChange={this.onChangeDescripcion}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Cancelar
            </Button>
            <Button variant={this.state.variante} onClick={this.ejecutar} disabled={this.state.loading}>
              {this.state.loading ? "Guardando..." : this.state.modalLabel}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal //*******************************Modal para eliminar
          show={this.state.modalEliminar}
          onHide={this.closeModalEliminar}
          size="sm"
          centered
        >
          <Modal.Header>
            <Modal.Title>Eliminar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Seguro que desea eliminar este registro{" "}
            <strong>{this.state.nom_direccion}</strong> ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModalEliminar}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={this.ejecutar}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
        <ReactTooltip />
      </Layout >
    );
  }
}

export default Grupos;
