import { MDBContainer } from "mdbreact";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Navegacion from "./Navegacion";
import Pie from "./Pie.js";

export default class Layout extends Component {
  constructor(props) {
    super(props);
    const token = sessionStorage.getItem("api_token");
    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }

    this.state = {
      loggedIn: loggedIn,
    };
  }

  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/"></Redirect>;
    }

    return (
      <div>
        <Navegacion />
        <MDBContainer fluid style={{ marginBottom: "150px" }}>
          {this.props.children}
        </MDBContainer>
        <Pie />
      </div>
    );
  }
}
