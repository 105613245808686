import React, { Component } from "react";
import { BrowserRouter, Route, Router, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Escritorio from "./pages/Escritorio";
import Promovidos from "./pages/Promovidos";
import Reportes from "./pages/Reportes";
import Logout from "./pages/Logout";
import Personas from "./pages/Personas";
import Grupos from "./pages/Grupos";
import Roles from "./pages/Roles";
import Mensajes from "./pages/Mensajes";
import Usuarios from "./pages/Usuarios";
import Sistema from "./pages/Sistema";

import "./estilos.css";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/escritorio" component={Escritorio} />
          <Route exact path="/promovidos" component={Promovidos} />
          <Route exact path="/personas" component={Personas} />
          <Route exact path="/grupos" component={Grupos} />
          <Route exact path="/roles" component={Roles} />
          <Route exact path="/mensajes" component={Mensajes} />
          <Route exact path="/reportes" component={Reportes} />
          <Route exact path="/usuarios" component={Usuarios} />
          <Route exact path="/sistema" component={Sistema} />
          <Route exact path="/logout" component={Logout} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
