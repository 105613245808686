import React, { Component } from "react";
import Layout from "../layout/Layout";
import axios from "axios";
import 'moment/locale/es';
import ReactTooltip from "react-tooltip";
import { Pie, Line, Bar } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBDataTable,
  MDBTypography
} from "mdbreact";
import Select from "react-select";

var demarcacionOptions = [
  { value: "", label: "TODAS" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
]

export default class Escritorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinadoresCantidad: 0,
      gestoresCantidad: 0,
      jefeDeFamiliaCantidad: 0,
      promovidosCantidad: 0,
      loading: false,
      estadisticas: null,
      promotores: [],
      colonias: [],
      localidades: [],
      demarcacion: { value: "", label: "TODAS" },
      seccion: { value: "", label: "TODAS" },
      seccionesOpc: [],
      dataPie: {
        labels: ["Femenino", "Masculino", "NULO"],
        datasets: [
          {
            data: [],
            backgroundColor: [
              "#ad1457",
              "#0d47a1",
              "#212121",
            ],
            hoverBackgroundColor: [
              "#c2185b",
              "#1565c0",
              "#2E2E2E",
            ]
          }
        ]
      },
      dataLine: {
        labels: [],
        datasets: [
          {
            label: "Edades",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "#1a237e80",
            borderColor: "#1a237e",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "rgb(205, 130,1 58)",
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderWidth: 10,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgb(0, 0, 0)",
            pointHoverBorderColor: "rgba(220, 220, 220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: []
          }
        ]
      }
    };
  }

  componentDidMount = () => {
    this.cargaReportes();
  };
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  cargaReportes = () => {
    this.setState({ loading: true });
    axios.get("https://" + process.env.REACT_APP_URL + "/temp/",
      {
        params: { api_token: sessionStorage.api_token }
      }
    ).then((res) => {
      this.setState({ estadisticas: res.data, loading: false });
      this.setState({ estadisticas: res.data, loading: false });
      var dataPie = { ...this.state.dataPie }
      var dataLine = { ...this.state.dataLine }
      dataPie.datasets[0].data = [res.data.sexos.Femenino, res.data.sexos.Masculino, res.data.sexos.Nulo];
      dataLine.labels = res.data.edades.etiquetas;
      dataLine.datasets[0].data = res.data.edades.cantidad;
      this.setState({
        dataPie: dataPie,
        promovidosCantidad: res.data.promovidosCantidad,
        coordinadoresCantidad: res.data.coordinadoresCantidad,
        gestoresCantidad: res.data.gestoresCantidad,
        jefeDeFamiliaCantidad: res.data.jefeDeFamiliaCantidad,
        dataLine: dataLine,
        promotores: res.data.promotores,
        colonias: res.data.colonias,
        localidades: res.data.localidades,
      });
    });
  };

  aplicarFiltros = () => {
    this.setState({ loading: true });
    axios.get("https://" + process.env.REACT_APP_URL + "/temp/",
      {
        params: {
          api_token: sessionStorage.api_token,
          demarcacion: this.state.demarcacion.value,
          seccion: this.state.seccion.value
        }
      }
    ).then((res) => {
      console.log(res.data)
      this.setState({ estadisticas: res.data, loading: false });
      this.setState({ estadisticas: res.data, loading: false });
      var dataPie = { ...this.state.dataPie }
      var dataLine = { ...this.state.dataLine }
      dataPie.datasets[0].data = [res.data.sexos.Femenino, res.data.sexos.Masculino, res.data.sexos.Nulo];
      dataLine.labels = res.data.edades.etiquetas;
      dataLine.datasets[0].data = res.data.edades.cantidad;
      this.setState({
        dataPie: dataPie,
        promovidosCantidad: res.data.promovidosCantidad,
        coordinadoresCantidad: res.data.coordinadoresCantidad,
        gestoresCantidad: res.data.gestoresCantidad,
        jefeDeFamiliaCantidad: res.data.jefeDeFamiliaCantidad,
        dataLine: dataLine,
        promotores: res.data.promotores,
        colonias: res.data.colonias,
        localidades: res.data.localidades,
      });
    });
  };

  handleChangeDemarcacion = (selectedOption) => {
    this.setState({
      seccionesOpc: [
        { value: "", label: "TODAS" }],
      seccion: { value: "", label: "TODAS" }
    })
    axios.get("https://" + process.env.REACT_APP_URL + "/temp/seccion_demarcacion/",
      {
        params: { api_token: sessionStorage.api_token, demarcacion: selectedOption.value }
      }
    ).then((res) => {
      this.setState({ seccionesOpc: res.data })
    });
    this.setState({ demarcacion: selectedOption })
  };

  handleChangeSeccion = (selectedOption) => {
    this.setState({ seccion: selectedOption })
  };


  render() {

    let dataColonia = {
      columns: [
        {
          label: 'Colonia',
          field: 'nombre',

        },
        {
          label: 'Promovidos',
          field: 'cantidad',

        },
      ],
      rows: this.state.colonias
    };

    let dataPromotores = {
      columns: [
        {
          label: 'Promotor',
          field: 'nombre',

        },
        {
          label: 'Promovidos',
          field: 'cantidad',

        },
      ],
      rows: this.state.promotores
    };

    let dataLocalidad = {
      columns: [
        {
          label: 'Localidad',
          field: 'nombre',

        },
        {
          label: 'Promovidos',
          field: 'cantidad',

        },
      ],
      rows: this.state.localidades
    };

    return (
      <div>
        <Layout>
          <MDBContainer>
            <MDBRow style={{ marginTop: "50px" }}>
              <MDBCol xs="12" sm="5">
                Demarcación
                  <Select
                  name="demarcacion"
                  labelKey="label"
                  valueKey="value"
                  classNamePrefix="select"
                  defaultValue={this.state.demarcacion}
                  value={this.state.demarcacion}
                  onChange={this.handleChangeDemarcacion}
                  options={demarcacionOptions}
                />
              </MDBCol>
              <MDBCol xs="12" sm="5">
                Sección
                  <Select
                  isDisabled={this.state.demarcacion.value == "" ? true : false}
                  name="seccion"
                  labelKey="seccion"
                  valueKey="id"
                  classNamePrefix="select"
                  value={this.state.seccion}
                  onChange={this.handleChangeSeccion}
                  options={this.state.seccionesOpc}
                />
              </MDBCol>
              <MDBCol xs="12" sm="2">
                <br />
                <button type="button" class="btn-lg btn-block btn-outline-info waves-effect" onClick={this.aplicarFiltros}>Filtrar</button>
              </MDBCol>
            </MDBRow>
            <MDBRow style={{ marginTop: "50px" }}>
              <MDBCol md="6" style={{ marginBottom: "50px" }}>
                <MDBCard style={{ margin: "auto" }}>
                  <MDBCardBody>
                    <MDBTypography tag='h1' variant="display-2" className="text-center">{this.state.promovidosCantidad}</MDBTypography>
                  </MDBCardBody>
                  <div className="rounded-bottom danger-color-dark text-center pt-3">
                    <ul className="list-unstyled list-inline font-small">
                      <li className="list-inline-item pr-2 white-text">
                        Total de Personas
                      </li>
                    </ul>
                  </div>
                </MDBCard>
              </MDBCol>
              <MDBCol md="2" style={{ marginBottom: "50px" }}>
                <MDBCard style={{ margin: "auto" }}>
                  <MDBCardBody>
                    <MDBTypography tag='h1' variant="display-5" className="text-center">{this.state.coordinadoresCantidad}</MDBTypography>
                  </MDBCardBody>
                  <div className="rounded-bottom stylish-color text-center pt-3">
                    <ul className="list-unstyled list-inline font-small">
                      <li className="list-inline-item pr-2 white-text">
                        Coordinadores
                      </li>
                    </ul>
                  </div>
                </MDBCard>
              </MDBCol>
              <MDBCol md="2" style={{ marginBottom: "50px" }}>
                <MDBCard style={{ margin: "auto" }}>
                  <MDBCardBody>
                    <MDBTypography tag='h1' variant="display-5" className="text-center">{this.state.gestoresCantidad}</MDBTypography>
                  </MDBCardBody>
                  <div className="rounded-bottom stylish-color text-center pt-3">
                    <ul className="list-unstyled list-inline font-small">
                      <li className="list-inline-item pr-2 white-text">
                        Gestores
                      </li>
                    </ul>
                  </div>
                </MDBCard>
              </MDBCol>
              <MDBCol md="2" style={{ marginBottom: "50px" }}>
                <MDBCard style={{ margin: "auto" }}>
                  <MDBCardBody>
                    <MDBTypography tag='h1' variant="display-5" className="text-center">{this.state.jefeDeFamiliaCantidad}</MDBTypography>
                  </MDBCardBody>
                  <div className="rounded-bottom stylish-color text-center pt-3">
                    <ul className="list-unstyled list-inline font-small">
                      <li className="list-inline-item pr-2 white-text">
                        Jefes de Familia
                      </li>
                    </ul>
                  </div>
                </MDBCard>
              </MDBCol>
              <MDBCol md="6" style={{ marginBottom: "50px" }}>
                <MDBCard style={{ margin: "auto" }}>
                  <MDBCardBody>
                    <Pie data={this.state.dataPie} options={{ responsive: true }} />
                  </MDBCardBody>
                  <div className="rounded-bottom purple darken-4 text-center pt-3">
                    <ul className="list-unstyled list-inline font-small">
                      <li className="list-inline-item pr-2 white-text">
                        Sexo
                      </li>
                    </ul>
                  </div>
                </MDBCard>
              </MDBCol>
              <MDBCol md="6" style={{ marginBottom: "50px" }}>
                <MDBCard style={{ margin: "auto" }}>
                  <MDBCardBody>
                    <Line data={this.state.dataLine} options={{
                      responsive: true, legend: {
                        display: false
                      }
                    }} />
                  </MDBCardBody>
                  <div className="rounded-bottom indigo darken-4 text-center pt-3">
                    <ul className="list-unstyled list-inline font-small">
                      <li className="list-inline-item pr-2 white-text">
                        Edades
                      </li>
                    </ul>
                  </div>
                </MDBCard>
              </MDBCol>
              <MDBCol md="6" style={{ marginBottom: "50px" }}>
                <MDBCard style={{ margin: "auto" }}>
                  <MDBCardBody>
                    <MDBDataTable
                      striped
                      bordered
                      small
                      entriesLabel='N. de entradas'
                      infoLabel={['', '-', 'de', '']}
                      paginationLabel={[<FontAwesomeIcon icon={faChevronLeft} />, <FontAwesomeIcon icon={faChevronRight} />]}
                      searchLabel="Buscar"
                      data={dataPromotores}
                    />
                  </MDBCardBody>
                  <div className="rounded-bottom amber darken-3 text-center pt-3">
                    <ul className="list-unstyled list-inline font-small">
                      <li className="list-inline-item pr-2 white-text">
                        Promotores
                      </li>
                    </ul>
                  </div>
                </MDBCard>
              </MDBCol>
              <MDBCol md="6" style={{ marginBottom: "50px" }}>
                <MDBCard style={{ margin: "auto" }}>
                  <MDBCardBody>
                    <MDBDataTable
                      striped
                      bordered
                      small
                      entriesLabel='N. de entradas'
                      infoLabel={['', '-', 'de', '']}
                      paginationLabel={[<FontAwesomeIcon icon={faChevronLeft} />, <FontAwesomeIcon icon={faChevronRight} />]}
                      searchLabel="Buscar"
                      data={dataLocalidad}
                    />
                  </MDBCardBody>
                  <div className="rounded-bottom green darken-4 text-center pt-3">
                    <ul className="list-unstyled list-inline font-small">
                      <li className="list-inline-item pr-2 white-text">
                        Localidad
                      </li>
                    </ul>
                  </div>
                </MDBCard>
              </MDBCol>
              <MDBCol md="6" style={{ marginBottom: "50px" }}>
                <MDBCard style={{ margin: "auto" }}>
                  <MDBCardBody>
                    <MDBDataTable
                      striped
                      bordered
                      small
                      entriesLabel='N. de entradas'
                      infoLabel={['', '-', 'de', '']}
                      paginationLabel={[<FontAwesomeIcon icon={faChevronLeft} />, <FontAwesomeIcon icon={faChevronRight} />]}
                      searchLabel="Buscar"
                      data={dataColonia}
                    />
                  </MDBCardBody>
                  <div className="rounded-bottom light-blue darken-4 text-center pt-3">
                    <ul className="list-unstyled list-inline font-small">
                      <li className="list-inline-item pr-2 white-text">
                        Colonias
                      </li>
                    </ul>
                  </div>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          <ReactTooltip />
        </Layout>
      </div >
    );


  }
}
