import React, { Component } from "react";
import { Link } from "react-router-dom";
import Layout from "../layout/Layout";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBInput,
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBCol,
  MDBBtn,
} from "mdbreact";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faFileUpload,
  faPlusCircle,
  faPowerOff,
  faSatelliteDish,
  faUpload,
  faTrash,
  faUsers,
  faDesktop,
  faEnvelope,
  faSms,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";

export default class Sistema extends Component {
  render() {
    return (
      <Layout>
        <MDBContainer className="mt-4">
          <Link to="/usuarios" className="btn btn-primary">
            <FontAwesomeIcon icon={faUsers} size="lg" /> Usuarios del Sistema
          </Link>
          <Link to="/roles" className="btn btn-primary">
            <FontAwesomeIcon icon={faUsers} size="lg" /> Roles de Usuario
          </Link>

          <Link className="btn btn-dark disabled">
            <FontAwesomeIcon icon={faSitemap} size="lg" /> Roles Estructura
            Electoral
          </Link>

          <Link className="btn btn-dark disabled">
            <FontAwesomeIcon icon={faSms} size="lg" /> Configuración SMS
          </Link>
        </MDBContainer>
      </Layout>
    );
  }
}
