import React, { Component } from 'react'
import Layout from "../layout/Layout";
import PropagateLoader from "react-spinners/PropagateLoader";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash, faMale, faFemale } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReactTooltip from "react-tooltip";
import Select from 'react-select'
import {
  MDBBtn,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBInput,
} from "mdbreact";
import { Button, ButtonGroup, Modal } from 'react-bootstrap';


export default class Mensajes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      loading: false,
      gruposOptions: "",
      mensaje: "",
      grupos: [],
      mensajes: [],
      caracteresRestantes: 160,
      limite: 0,
      modalEliminar: false
    }
  }

  componentDidMount = () => {
    /* Alimenta el select de grupos*/
    axios({
      method: "get",
      url:
        "https://" + process.env.REACT_APP_URL + "/api/Grupo?api_token=" +
        sessionStorage.api_token,
      data: {},
    }).then((res) => {
      let opciones = [];
      res.data.map((opc) => {
        opciones.push({ "value": opc.id, "label": opc.nombre })
      })
      this.setState({ gruposOptions: opciones, loading: false });
    });




    /* Alimenta la tabla de Mensajes Recibidos 

    axios({
      method: "get",
      url:
        "https://" + process.env.REACT_APP_URL + "/api/Mensaje?api_token=" +
        sessionStorage.api_token,
      data: {},
    }).then((res) => {
      this.setState({ mensajes: res.data, loading: false });
    }) 

    */

    this.actualiza()

    this.interval = setInterval(() =>
      this.actualiza()
      , 10000);
  }

  actualiza = () => {
    this.cargaSaldo()
    this.cargaTabla()
  }

  cargaSaldo = () => {
    axios({
      method: "get",
      url:
        "https://sms.sii.mx/api/Saldo?api_token=" +
        process.env.REACT_APP_TOKEN,
      data: {},
    }).then((res) => {
      this.setState({ limite: res.data, loading: false });
    })
  }
  cargaTabla = () => {
    axios({
      method: "get",
      url:
        "https://" + process.env.REACT_APP_URL + "/api/Mensaje?api_token=" +
        sessionStorage.api_token,
      data: {},
    }).then((res) => {
      this.setState({ mensajes: res.data, loading: false });
    })
  }



  programaMensajes = () => {
    this.setState({ loading: true });

    let data = {
      //Recoger la información para el registro
      mensaje: this.state.mensaje,
      grupos: this.state.grupos,
      api_token: sessionStorage.api_token
    };
    let config = {};

    axios
      .post("https://" + process.env.REACT_APP_URL + "/api/Mensaje", data, config)
      .then(res => {
        toast.success(res.data.text, { autoClose: 1000 });
        this.setState({ loading: false });
        this.cargaTabla()
      });


  }


  handleChangeMensaje = (e) => {
    this.setState({ mensaje: e.target.value })
  }
  handleKeyUpMensaje = () => {
    this.setState({ caracteresRestantes: 160 - this.state.mensaje.length })
  }
  handleChangeGrupos = (selectedOption) => {
    this.setState({ grupos: selectedOption })
  }
  showModalEliminar = () => {
    this.setState({ modalEliminar: true });
  };
  closeModalEliminar = () => {
    this.setState({ modalEliminar: false });
  };


  ModalEliminar = row => {
    this.showModalEliminar();
    this.setState({
      modalAccion: "delete",
      id: row.id,
    });
  };

  borra = () => {
    let data = {
      api_token: sessionStorage.api_token,
    };
    let config = {};
    axios
      .delete(
        "https://" + process.env.REACT_APP_URL + "/api/Mensaje/" +
        this.state.id +
        "?api_token=" +
        sessionStorage.api_token,
        data,
        config
      )
      .then(res => {
        this.closeModalEliminar();
        toast.success(res.data.text, { autoClose: 1000 });
        this.cargaTabla();
        this.setState({ loading: false });
      });
  };






  GetActionFormat = (cell, row) => {
    let estatus = row.estatus
    if (!estatus) {
      estatus = ""
    }
    return (
      <div>
        <ButtonGroup size="sm">
          <button
            type="button"
            className="btn btn-outline-danger btn-sm"
            size="sm"
            disabled={estatus.startsWith("Envia") && "disabled"}
            onClick={() => this.ModalEliminar(row)}
            data-tip="Eliminar"
          >
            <FontAwesomeIcon icon={faTrash} size="lg" />
          </button>
        </ButtonGroup>
      </div>
    );
  };




  render() {

    const columns = [
      {
        dataField: "id",
        text: "ID",
        headerStyle: () => {
          return { width: "100px" };
        },
      },
      {
        dataField: "numero",
        text: "Numero",
        sort: true,
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        dataField: "mensaje",
        text: "Mensaje",
        sort: true,
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        dataField: "fechahora",
        text: "Fecha",
        sort: true,
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        dataField: "estatus",
        text: "Estatus",
        sort: true,
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        dataField: "",
        text: "Acción",
        formatter: this.GetActionFormat,
        classes: "p-1",
        headerStyle: () => {
          return { width: "80px" };
        },
      }
    ]
    const { SearchBar } = Search;


    return (
      <Layout>
        <ToastContainer />
        <div
          id="cargando"
          className={this.state.loading ? "parentDisable" : ""}
          width="100%"
        >
          <div className="overlay-box">
            <PropagateLoader
              // css={}
              sizeUnit={"px"}
              size={25}
              color={"#000000"}
              loading={this.state.loading}
            />
          </div>
        </div>

        <MDBContainer>

          <MDBRow>
            <MDBCol xs="12" sm="12">
              <MDBCard className="mt-3">
                <MDBCardHeader color="primary-color">
                  <MDBRow>
                    <MDBCol>
                      <MDBIcon icon="envelope" />
                      {" "}Envio de Mensajes SMS
                    </MDBCol>
                    <MDBCol className="text-right"><b>{this.state.limite.limite}</b> Mensajes dispobiles</MDBCol>
                  </MDBRow>
                </MDBCardHeader>
                <MDBCardBody>

                  <MDBCardText>
                    Puedes escribir <strong>{this.state.caracteresRestantes}</strong> caracteres restantes.
          Puedes usar el comodin #nombre en el mensaje.


      <MDBRow>
                      <MDBCol xs="12" sm="8">
                        <MDBInput
                          type="textarea"
                          label="Mensaje"
                          rows="2"
                          icon="pencil-alt"
                          onChange={this.handleChangeMensaje}
                          onKeyUp={this.handleKeyUpMensaje}
                        />
                      </MDBCol>
                      <MDBCol xs="12" sm="4">
                        <Select

                          name="grupo_id"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={this.state.grupos}
                          onChange={this.handleChangeGrupos}
                          options={this.state.gruposOptions}
                        />
                      </MDBCol>
                    </MDBRow>



                  </MDBCardText>
                  <MDBBtn color="secondary" onClick={this.programaMensajes}>Programar Envio</MDBBtn>
                </MDBCardBody>
              </MDBCard>

            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol xs="12" sm="12" className="mt-4">

              <ToolkitProvider
                keyField="id"
                data={this.state.mensajes}
                columns={columns}
                bootstrap4
                search={{ searchFormatted: true }}
              >
                {props => (
                  <div>
                    <div className="row">
                      <div className="col-6 text-right">
                        <SearchBar
                          placeholder="Buscar..."
                          {...props.searchProps}
                          className="self-align-end"
                        />
                      </div>
                    </div>

                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory()}
                      hover
                    />
                  </div>
                )}
              </ToolkitProvider>


            </MDBCol>


          </MDBRow>




        </MDBContainer>


        <Modal //*******************************Modal para eliminar
          show={this.state.modalEliminar}
          onHide={this.closeModalEliminar}
          size="sm"
          centered
        >
          <Modal.Header>
            <Modal.Title>Eliminar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Seguro que desea eliminar este registro{" "}
            <strong>{this.state.mensaje}</strong> ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModalEliminar}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={this.borra}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>



        <ReactTooltip />

      </Layout>
    )
  }
}

