import React, { Component } from "react";
import Layout from "../layout/Layout";
import PropagateLoader from "react-spinners/PropagateLoader";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEdit,
  faTrash,
  faMale,
  faFemale,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";

import Select from "react-select";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import ReactTooltip from "react-tooltip";
import { MDBIcon, MDBInput } from "mdbreact";
import { MDBFormInline } from "mdbreact";
import { MDBRow } from "mdbreact";
import { MDBCol } from "mdbreact";
import { MDBContainer } from "mdbreact";
import { ButtonGroup } from "react-bootstrap";

class Personas extends Component {
  constructor(props) {
    super(props);
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.state = {
      // Estado relacionado con el modelo
      id: "",
      nombre: "",
      ap_paterno: "",
      ap_materno: "",
      verificado: "",
      sexo: "",
      fecha_nacimiento: "",
      celular: "",
      correo: "",
      facebook: "",
      domicilio: "",
      colonia: "",
      localidad: "",
      demarcacion: "",
      seccion: "",
      promotor_id: "",
      promotor_value: "",
      promotor: "",
      grupo_id: "",
      grupos: "",
      gruposOptions: "",

      // Estados para la ventana modal
      modal: false, // ventana modal generica para modificar y Nuevo cerrada
      modalEliminar: false, //Modal Eliminar
      modalAccion: "", // Acción del boton principal de la ventana modal para el switch
      modalTitle: "", // Titulo para la ventana modal generica
      personas: [], // Listado de elementos para la tabla
      loading: false,
    };
  }

  componentDidMount = () => {
    this.cargaTabla();
  };
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  cargaTabla = () => {
    this.setState({ loading: true });
    axios({
      method: "get",
      url:
        "https://" + process.env.REACT_APP_URL + "/api/Persona?api_token=" +
        sessionStorage.api_token,
      data: {},
    }).then((res) => {
      this.setState({ personas: res.data, loading: false });
    });
    /* Alimenta el select de grupos*/
    axios({
      method: "get",
      url:
        "https://" + process.env.REACT_APP_URL + "/api/Grupo?api_token=" +
        sessionStorage.api_token,
      data: {},
    }).then((res) => {
      let opciones = [];
      res.data.map((opc) => {
        opciones.push({ value: opc.id, label: opc.nombre });
      });
      this.setState({ gruposOptions: opciones, loading: false });
    });
    /* Alimenta el select de promotores*/
    axios({
      method: "get",
      url:
        "https://" + process.env.REACT_APP_URL + "/api/Persona?api_token=" +
        sessionStorage.api_token,
      data: {},
    }).then((res) => {
      let opciones = [];
      res.data.map((opc) => {
        opciones.push({ value: opc.id, label: opc.nombrecompleto });
      });
      this.setState({ promotoresOptions: opciones, loading: false });
    });
  };

  showModalEliminar = () => {
    this.setState({ modalEliminar: true });
  };

  closeModalEliminar = () => {
    this.setState({ modalEliminar: false });
  };

  showModal = () => {
    this.setState({ modal: true });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  ModalNuevo = () => {
    this.showModal();
    this.setState({
      modalLabel: "Guardar",
      variante: "primary",
      modalAccion: "insert",
      modalTitle: "Registro nuevo",
      id: "",
      nombre: "",
      ap_paterno: "",
      ap_materno: "",
      verificado: "",
      sexo: "",
      fecha_nacimiento: "",
      celular: "",
      correo: "",
      facebook: "",
      domicilio: "",
      colonia: "",
      localidad: "",
      demarcacion: "",
      seccion: "",
      promotor_id: "",
      promotor: "",
      grupo_id: "",
      grupos: "",
    });
  };
  OpenLink = () => {
    window.open("https://" + process.env.REACT_APP_URL + "/pdf/?api_token=" +
      sessionStorage.api_token, "_blank")
  };

  ModalModificar = (row) => {
    this.showModal();
    this.setState({
      modalLabel: "Modificar",
      variante: "warning",
      modalAccion: "update",
      modalTitle: "Modificación",
      id: row.id,
      nombre: row.nombre,
      ap_paterno: row.ap_paterno,
      ap_materno: row.ap_materno,
      verificado: row.verificado,
      sexo: row.sexo,
      fecha_nacimiento: row.fecha_nacimiento,
      celular: row.celular,
      correo: row.correo,
      facebook: row.facebook,
      domicilio: row.domicilio,
      colonia: row.colonia,
      localidad: row.localidad,
      demarcacion: row.demarcacion,
      seccion: row.seccion,
      promotor_id: row.promotor_id,
      promotor: row.promotor,
      grupo_id: row.grupo_id,
      grupos: row.grupos,
    });
  };

  ModalEliminar = (row) => {
    this.showModalEliminar();
    this.setState({
      modalAccion: "delete",
      id: row.id,
      nombre: row.nombre,
      ap_paterno: row.ap_paterno,
      ap_materno: row.ap_materno,
      verificado: row.verificado,
      sexo: row.sexo,
      fecha_nacimiento: row.fecha_nacimiento,
      celular: row.celular,
      correo: row.correo,
      facebook: row.facebook,
      domicilio: row.domicilio,
      colonia: row.colonia,
      localidad: row.localidad,
      demarcacion: row.demarcacion,
      seccion: row.seccion,
      promotor_id: row.promotor_id,
      grupo_id: row.grupo_id,
    });
  };

  validaFormulario = (e) => {
    e.preventDefault();
    if (this.state.nombre === "")
      toast.error("El nombre es obligatorio", { autoClose: 2000 });
    else this.ejecutar();
  };

  ejecutar = () => {
    this.setState({ loading: true });
    switch (this.state.modalAccion) {
      case "update":
        this.actualiza();
        break;
      case "insert":
        this.registra();
        break;
      case "delete":
        this.borra();
        break;
      default:
    }
  };

  valida = (e) => {
    e.preventDefault();
    if ((this.state.nombre = ""))
      toast.error("El nombre es obligatorio", { autoClose: 2000 });
    else this.ejecutar();
  };

  registra = () => {
    let data = {
      //Recoger la información para el registro
      nombre: this.state.nombre,
      ap_paterno: this.state.ap_paterno,
      ap_materno: this.state.ap_materno,
      verificado: this.verificado,
      sexo: this.state.sexo,
      fecha_nacimiento: this.state.fecha_nacimiento,
      celular: this.state.celular,
      correo: this.state.correo,
      facebook: this.state.facebook,
      domicilio: this.state.domicilio,
      colonia: this.state.colonia,
      localidad: this.state.localidad,
      demarcacion: this.state.demarcacion,
      seccion: this.state.seccion,
      promotor_id: this.state.promotor_id,
      promotor: this.state.promotor,
      grupo_id: this.state.grupo_id,
      grupos: this.state.grupos,
      api_token: sessionStorage.api_token,
    };
    let config = {};

    axios
      .post("https://" + process.env.REACT_APP_URL + "/api/Persona", data, config)
      .then((res) => {
        this.closeModal();
        toast.success(res.data.text, { autoClose: 1000 });
        this.cargaTabla();
        this.setState({ loading: false });
      });
  };

  actualiza = () => {
    let data = {
      //Recoger la información para el registro
      id: this.state.id,
      nombre: this.state.nombre,
      ap_paterno: this.state.ap_paterno,
      ap_materno: this.state.ap_materno,
      verificado: this.state.verificado,
      sexo: this.state.sexo,
      fecha_nacimiento: this.state.fecha_nacimiento,
      celular: this.state.celular,
      correo: this.state.correo,
      facebook: this.state.facebook,
      domicilio: this.state.domicilio,
      colonia: this.state.colonia,
      localidad: this.state.localidad,
      demarcacion: this.state.demarcacion,
      seccion: this.state.seccion,
      promotor_id: this.state.promotor_id,
      grupo_id: this.state.grupo_id,
      promotor: this.state.promotor,
      grupos: this.state.grupos,
      api_token: sessionStorage.api_token,
    };
    let config = {};

    axios
      .put(
        "https://" + process.env.REACT_APP_URL + "/api/Persona/" + this.state.id,
        data,
        config
      )
      .then((res) => {
        this.closeModal();
        toast.success(res.data.text, { autoClose: 1000 });
        this.cargaTabla();
        this.setState({ loading: false });
      });
  };

  borra = () => {
    let data = {
      api_token: sessionStorage.api_token,
    };
    let config = {};
    axios
      .delete(
        "https://" + process.env.REACT_APP_URL + "/api/Persona/" +
        this.state.id +
        "?api_token=" +
        sessionStorage.api_token,
        data,
        config
      )
      .then((res) => {
        this.closeModalEliminar();
        toast.success(res.data.text, { autoClose: 1000 });
        this.cargaTabla();
        this.setState({ loading: false });
      });
  };

  onChangeNombre = (e) => {
    this.setState({ nombre: e.target.value });
  };

  onChangeApPaterno = (e) => {
    this.setState({ ap_paterno: e.target.value });
  };

  onChangeApMaterno = (e) => {
    this.setState({ ap_materno: e.target.value });
  };

  onChangeFechaNacimiento = (e) => {
    this.setState({ fecha_nacimiento: e.target.value });
  };

  onChangeCorreo = (e) => {
    this.setState({ correo: e.target.value });
  };

  onChangeFacebook = (e) => {
    this.setState({ facebook: e.target.value });
  };

  onChangeDomicilio = (e) => {
    this.setState({ domicilio: e.target.value });
  };

  onChangeCelular = (e) => {
    this.setState({ celular: e.target.value });
  };

  onChangeColonia = (e) => {
    this.setState({ colonia: e.target.value });
  };

  onChangeLocalidad = (e) => {
    this.setState({ localidad: e.target.value });
  };

  onChangeDemarcacion = (e) => {
    this.setState({ demarcacion: e.target.value });
  };

  onChangeSeccion = (e) => {
    this.setState({ seccion: e.target.value });
  };

  onChangePromotorId = (e) => {
    this.setState({ promotor_id: e.target.value });
  };

  onChangeGrupoId = (e) => {
    this.setState({ grupo_id: e.target.value });
  };

  handleChange = (selectedOption) => {
    this.setState({ grupos: selectedOption });
  };

  handleChangePromotor = (selectedOption) => {
    this.setState({ promotor: selectedOption });
    this.setState({ promotor_id: selectedOption.value });
  };

  onChangeSexo = (nr) => () => {
    this.setState({
      sexo: nr,
    });
  };

  sexoFormatter = (cell, row) => {
    let sexo = cell.substring(0, 1);
    let mostrar = "";
    mostrar =
      sexo == "f" ? (
        <div>
          <FontAwesomeIcon icon={faFemale} size="lg" /> Femenino{" "}
        </div>
      ) : (
        <div>
          <FontAwesomeIcon icon={faMale} size="lg" /> Masculino
        </div>
      );
    return mostrar;
  };

  /**/
  grupoFormatter = (cell, row) => {
    let grupos = cell.map((grupo, i) => (
      <span className="badge badge-pill badge-secondary m-1" key={i}>
        {grupo.nombre}
      </span>
    ));
    return <span>{grupos}</span>;
  };

  GetActionFormat = (cell, row) => {
    return (
      <div>
        <ButtonGroup size="sm">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            size="sm"
            onClick={() => this.ModalModificar(row)}
            data-tip="Modificar"
          >
            <FontAwesomeIcon icon={faEdit} size="lg" />
          </button>

          <button
            type="button"
            className="btn btn-outline-danger btn-sm"
            size="sm"
            onClick={() => this.ModalEliminar(row)}
            data-tip="Eliminar"
          >
            <FontAwesomeIcon icon={faTrash} size="lg" />
          </button>
        </ButtonGroup>
      </div>
    );
  };

  render() {
    const columns = [
      {
        dataField: "nombre",
        text: "completo",
        hidden: true,
        formatter: (cell, row) => row.nombre + " " + row.ap_paterno + " " + row.ap_materno,
        headerStyle: () => {
          return { width: "100px" };
        },
      },
      {
        dataField: "id",
        text: "ID",
        hidden: true,
        headerStyle: () => {
          return { width: "100px" };
        },
      },
      {
        dataField: "nombre",
        text: "Nombre",
        sort: true,
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        dataField: "ap_paterno",
        text: "Ap Paterno",
        sort: true,
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        dataField: "ap_materno",
        text: "Ap Materno",
        sort: true,
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        dataField: "verificado",
        text: "Verificado",
        sort: true,
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        dataField: "sexo",
        text: "Sexo",
        sort: true,
        headerStyle: () => {
          return { width: "80px" };
        },
      },
      {
        dataField: "fecha_nacimiento",
        text: "Fecha Nacimiento",
        sort: true,
        headerStyle: () => {
          return { width: "100px" };
        },
      },
      {
        dataField: "celular",
        text: "Celular",
        sort: true,
        headerStyle: () => {
          return { width: "100px" };
        },
      },
      {
        dataField: "correo",
        text: "Correo",
        sort: true,
        headerStyle: () => {
          return { width: "300px" };
        },
      },
      {
        dataField: "facebook",
        text: "Facebook",
        sort: true,
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        dataField: "domicilio",
        text: "Domicilio",
        sort: true,
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        dataField: "colonia",
        text: "Colonia",
        sort: true,
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        dataField: "localidad",
        text: "Localidad",
        sort: true,
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        dataField: "demarcacion",
        text: "Demarcacion",
        sort: true,
        headerStyle: () => {
          return { width: "150px" };
        },
      },
      {
        dataField: "seccion",
        text: "Seccion",
        sort: true,
        headerStyle: () => {
          return { width: "150px" };
        },
      },
      {
        dataField: "promotor.nombrecompleto",
        text: "Promotor",
        sort: true,
        headerStyle: () => {
          return { width: "300px" };
        },
      },
      {
        dataField: "gruposString",
        text: "Grupos",
        sort: true,
        headerStyle: () => {
          return { width: "400px" };
        },
      },

      {
        dataField: "",
        text: "Acción",
        formatter: this.GetActionFormat,
        classes: "p-1",
        headerStyle: () => {
          return { width: "140px" };
        },
      },
    ];

    const { SearchBar } = Search;

    return (
      <Layout>
        <ToastContainer />
        <div
          id="cargando"
          className={this.state.loading ? "parentDisable" : ""}
          width="100%"
        >
          <div className="overlay-box">
            <PropagateLoader
              // css={}
              sizeUnit={"px"}
              size={25}
              color={"#000000"}
              loading={this.state.loading}
            />
          </div>
        </div>

        <div className="container mt-3">
          <div className="row">
            <div className="col">
              <h3 className="d-inline">Catálogo de Personas</h3>{" "}
              <small className="text-muted">
                Gestione las personas al rededor de su campaña, (Promovidos,
                Estructura, Coordinadores, etc.)
              </small>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 ">
              <ToolkitProvider
                keyField="id"
                data={this.state.personas}
                columns={columns}
                bootstrap4
                search={{ searchFormatted: true }}
              >
                {(props) => (
                  <div>
                    <div className="row">
                      <div className="col-6">
                        <button
                          className="btn btn-primary"
                          onClick={this.ModalNuevo}
                          data-tip="Nuevo"
                        >
                          <FontAwesomeIcon icon={faPlus} /> Nuevo
                        </button>
                        {sessionStorage.nivel == "5" && (
                          <button
                            className="btn btn-secondary"
                            data-tip="Descargar PDF"
                            onClick={this.OpenLink}
                          >

                            <FontAwesomeIcon icon={faFilePdf} /> Descargar PDF
                          </button>
                        )}
                      </div>
                      <div className="col-6 text-right">
                        <SearchBar
                          placeholder="Buscar..."
                          {...props.searchProps}
                          className="self-align-end"
                        />
                      </div>
                    </div>

                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory()}
                      hover
                    />
                  </div>
                )}
              </ToolkitProvider>{" "}
            </div>
          </div>
        </div>

        <Modal //***************************Modal para nuevo y modificar
          show={this.state.modal}
          onHide={this.closeModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <MDBIcon icon="user" /> Datos Generales
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol xs="12" sm="6">
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Nombre"
                        className="form-control"
                        type="text"
                        name="nombre"
                        value={this.state.nombre}
                        onChange={this.onChangeNombre}
                        outline
                        group
                        validate
                        error="wrong"
                        success="right"
                        required
                      />
                    </MDBCol>
                    <div className="w-100" />
                    <MDBCol>
                      <MDBInput
                        label="Primer Apellido"
                        className="form-control"
                        type="text"
                        name="ap_paterno"
                        value={this.state.ap_paterno}
                        onChange={this.onChangeApPaterno}
                        outline
                        group
                        validate
                        error="wrong"
                        success="right"
                      />
                    </MDBCol>
                    <div className="w-100" />
                    <MDBCol>
                      <MDBInput
                        label="Segundo Apellido"
                        className="form-control"
                        type="text"
                        name="ap_materno"
                        value={this.state.ap_materno}
                        onChange={this.onChangeApMaterno}
                        outline
                        group
                        validate
                        error="wrong"
                        success="right"
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol xs="12" sm="6">
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        label="Fecha de Nacimiento"
                        className="form-control"
                        type="date"
                        name="fecha_nacimiento"
                        value={this.state.fecha_nacimiento}
                        onChange={this.onChangeFechaNacimiento}
                        outline
                        group
                        validate
                        error="wrong"
                        success="right"
                        icon="birthday-cake"
                      />
                    </MDBCol>
                    <div className="w-100" />
                    <MDBCol>Sexo:</MDBCol>
                    <div className="w-100" />
                    <MDBCol>
                      <MDBFormInline>
                        <MDBIcon icon="female" />
                        &nbsp;
                        <MDBInput
                          onChange={this.onChangeSexo("Femenino")}
                          checked={
                            this.state.sexo === "Femenino" ? true : false
                          }
                          label="Femenino"
                          type="radio"
                          id="radio1"
                          containerClass="mr-5"
                        />
                        <MDBIcon icon="male" />
                        &nbsp;
                        <MDBInput
                          onChange={this.onChangeSexo("Masculino")}
                          checked={
                            this.state.sexo === "Masculino" ? true : false
                          }
                          label="Masculino"
                          type="radio"
                          id="radio2"
                          containerClass="mr-5"
                        />
                      </MDBFormInline>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow></MDBRow>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                  <br /> Dirección <MDBIcon icon="home" />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol xs="12" sm="6">
                  <MDBInput
                    label="Domicilio"
                    className="form-control"
                    type="text"
                    name="domicilio"
                    value={this.state.domicilio}
                    onChange={this.onChangeDomicilio}
                    outline
                    group
                    validate
                    error="wrong"
                    success="right"
                  />
                </MDBCol>
                <MDBCol xs="12" sm="6">
                  <MDBInput
                    label="Colonia"
                    className="form-control"
                    type="text"
                    name="colonia"
                    value={this.state.colonia}
                    onChange={this.onChangeColonia}
                    outline
                    group
                    validate
                    error="wrong"
                    success="right"
                  />
                </MDBCol>
                <div className="w-100" />
                <MDBCol xs="12" sm="4">
                  <MDBInput
                    label="Localidad"
                    className="form-control"
                    type="text"
                    name="localidad"
                    value={this.state.localidad}
                    onChange={this.onChangeLocalidad}
                    outline
                    group
                    validate
                    error="wrong"
                    success="right"
                  />
                </MDBCol>
                <MDBCol xs="12" sm="4">
                  <MDBInput
                    label="Demarcacion"
                    className="form-control"
                    type="text"
                    name="demarcacion"
                    value={this.state.demarcacion}
                    onChange={this.onChangeDemarcacion}
                    outline
                    group
                    validate
                    error="wrong"
                    success="right"
                  />
                </MDBCol>
                <MDBCol xs="12" sm="4">
                  <MDBInput
                    label="Seccion"
                    className="form-control"
                    type="text"
                    name="seccion"
                    value={this.state.seccion}
                    onChange={this.onChangeSeccion}
                    outline
                    group
                    validate
                    error="wrong"
                    success="right"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  Contacto <MDBIcon far icon="handshake" />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol xs="12" sm="12">
                  <MDBInput
                    label="Correo Electronico"
                    className="form-control"
                    type="email"
                    name="correo"
                    value={this.state.correo}
                    onChange={this.onChangeCorreo}
                    outline
                    group
                    validate
                    error="wrong"
                    success="right"
                    icon="envelope"
                  />
                </MDBCol>
                <MDBCol xs="12" sm="6">
                  <MDBInput
                    label="Facebook"
                    className="form-control"
                    type="text"
                    name="facebook"
                    value={this.state.facebook}
                    onChange={this.onChangeFacebook}
                    outline
                    group
                    validate
                    error="wrong"
                    success="right"
                    fabicon="facebook"
                  />
                </MDBCol>
                <MDBCol xs="12" sm="6">
                  <MDBInput
                    label="Celular"
                    className="form-control"
                    type="text"
                    name="celular"
                    value={this.state.celular}
                    onChange={this.onChangeCelular}
                    outline
                    group
                    validate
                    error="wrong"
                    success="right"
                    fabicon="mobile-alt"
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol xs="12" sm="12">
                  Promotor
                  <Select
                    name="promotor"
                    labelKey="nombrecompleto"
                    valueKey="id"
                    classNamePrefix="select"
                    value={this.state.promotor}
                    onChange={this.handleChangePromotor}
                    options={this.state.promotoresOptions}
                  />
                </MDBCol>

                <MDBCol xs="12" sm="12">
                  Grupos
                  <Select
                    required
                    isMulti
                    name="grupo_id"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={this.state.grupos}
                    onChange={this.handleChange}
                    options={this.state.gruposOptions}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Cancelar
            </Button>
            <Button variant={this.state.variante} onClick={this.validaFormulario} disabled={this.state.loading}>
              {this.state.loading ? "Guardando..." : this.state.modalLabel}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal //*******************************Modal para eliminar
          show={this.state.modalEliminar}
          onHide={this.closeModalEliminar}
          size="sm"
          centered
        >
          <Modal.Header>
            <Modal.Title>Eliminar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Seguro que desea eliminar este registro{" "}
            <strong>{this.state.nom_direccion}</strong> ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModalEliminar}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={this.ejecutar}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
        <ReactTooltip />
      </Layout>
    );
  }
}

export default Personas;
