import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Notifications, { notify } from "react-notify-toast";
import {
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardBody,
} from "mdbreact";

class Login extends Component {
  //state = {  }

  constructor(props) {
    super(props);
    //  const api_token = sessionStorage.getItem('api_token')

    this.state = {
      username: "",
      password: "",
      loggedIn: false,
      loading: false,
    };

    //   let loggedIn = false

    //this.onChange = this.onChange.bind(this)
    // this.submitForm = this.submitForm.bind(this)
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitForm = (e) => {
    e.preventDefault();

    const { username, password } = this.state;

    let data = {
      usuario: username,
      password: password,
      remember_me: true,
    };

    this.setState({ loading: true });

    fetch("https://" + process.env.REACT_APP_URL + "/api/Login", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.exito) {
          this.setState({ api_token: response.exito.api_token });
          sessionStorage.setItem("api_token", response.exito.api_token);
          //sessionStorage.setItem(Object.keys(response.exito),Object.values(response.exito))

          Object.keys(response.exito).forEach((element) => {
            // console.log(element+' ==> '+response.exito[element])
            sessionStorage.setItem(element, response.exito[element]);
          });

          this.setState({ loading: false });

          // this.setState({loggedIn:true})
          //     return (<Redirect to ='/documentos' />)
        } else {
          notify.show("E:" + response.error, "error", 2000);

          this.setState({ loading: false });
        }
      })
      .catch();
  };

  render() {
    if (sessionStorage.getItem("api_token")) {
      return <Redirect to="/escritorio" />;
    }
    return (
      <div className="login-background">
        <Notifications />

        <MDBContainer>
          <MDBRow>
            <MDBCol md="6" className="offset-md-3">
              <MDBRow style={{ marginTop: "50px" }}>
                <MDBCol md="8" className="offset-md-2">
                  <img
                    src={process.env.REACT_APP_URL + "/img/logo_login.png"}
                    className="img-fluid"
                    alt="Login Screen"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow style={{ marginTop: "50px" }}>
                <MDBCol md="12">
                  <MDBCard>
                    <MDBCardBody>
                      <form className="form-signin" onSubmit={this.submitForm}>
                        <p className="h5 text-center mb-4">Acceso</p>
                        <div className="grey-text">
                          <MDBInput
                            label="Usuario"
                            icon="user"
                            group
                            type="text"
                            validate
                            error="wrong"
                            success="right"
                            name="username"
                            id="username"
                            value={this.state.username}
                            onChange={this.onChange}
                          />
                          <MDBInput
                            name="password"
                            id="password"
                            label="Contraseña"
                            icon="lock"
                            group
                            type="password"
                            validate
                            value={this.state.password}
                            onChange={this.onChange}
                          />
                        </div>
                        <div className="text-center">
                          <div
                            className={this.state.loading ? "" : "hide-loading"}
                            width="100%"
                          >
                            <div className="overlay-box text-center">
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="sr-only">Cargando...</span>
                              </div>
                            </div>
                          </div>
                          <MDBBtn type="submit">
                            <MDBIcon icon="sign-in-alt" className="mr-2" />
                            Entrar
                          </MDBBtn>
                        </div>
                        <div className="text-center">
                          <MDBContainer style={{ marginTop: "20px" }}>
                            <p className="text-muted">
                              {process.env.REACT_APP_NAME} | Sistema de Simpatizantes
                              <br />
                              <br />
                              &copy;&nbsp;
                              <a
                                href="https://sii.mx"
                                target="_blank"
                                className="text-reset"
                              >sii.mx</a>
                              &nbsp;2021
                            </p>
                          </MDBContainer>
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

export default Login;
